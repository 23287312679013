import { render, staticRenderFns } from "./WarehousePdaLayout.vue?vue&type=template&id=4428320e&scoped=true&"
import script from "./WarehousePdaLayout.vue?vue&type=script&lang=js&"
export * from "./WarehousePdaLayout.vue?vue&type=script&lang=js&"
import style0 from "./WarehousePdaLayout.vue?vue&type=style&index=0&id=4428320e&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4428320e",
  null
  
)

export default component.exports